import React from "react"

import styled from 'styled-components';
import theme from "../styles/theme";

const StyledWrapper = styled.footer`
    background: ${theme.colors.darkGray};
    width: 100%;
    color: ${theme.colors.lightGray};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FooterContainer = styled.div`
    width: auto;
    display: flex;
    padding: 90px 0px 90px 0px;
    flex-direction: row;
    justify-content: flex-end;

    @media(max-width: 991px) {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

const FooterSection = styled.div`
    display: flex;
    text-align: right;
    flex-direction: column;
    padding-right: 30px;
    
    font-size: 0.7rem;
    letter-spacing: 1px;

    a {
        color: white;
        margin-bottom: 1.45rem;
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }

    border-right: 2px solid ${theme.colors.lightGray};

    :first-child {
        margin-right: 80px;
    }

    @media(max-width: 991px) {
        flex-direction: column;
        justify-content: flex-start;

        :first-child {
            margin-right: 0px;
            margin-bottom: 50px;
        }
    }

  
`;

const CopyrightContainer = styled.div`
    display: flex;
    background: ${theme.colors.black};
    width: 100%;
    padding: 10px;

    p {
        margin: 0 auto;
        font-size: 0.7rem;
    }

    a {
        color: ${theme.colors.lightGray};
    }
`;

const Footer = () => (
  <StyledWrapper>
    <FooterContainer>
        <FooterSection>
            <p><strong>Infofinder Sp. z o.o.</strong></p>
            <span>Wita Stwosza 2</span>
            <span>40-036 Katowice</span>
        </FooterSection>
        <FooterSection>
            <a href="mailto:biuro@infofinder.pl">biuro@infofnider.pl</a>
            <a href="tel:322055453">32 205 54 53</a>
            <span>Godziny otwarcia:</span>
            <span>od pon do pt w godz. 9 – 17</span>
        </FooterSection>
    </FooterContainer>
    <CopyrightContainer>
        <p>Copyright © {new Date().getFullYear()} InfoFinder Sp. z o. o.</p>
    </CopyrightContainer>
  </StyledWrapper>
)

export default Footer
