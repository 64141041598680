import React from "react"

import styled from 'styled-components';
import theme from "../styles/theme";

const HeroWrapper = styled.div`
  width: 100%;
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 87px;
  left: 0;
  background: ${theme.colors.header};
  padding-top: 30px;
  padding-bottom: 30px;

  border-top: 2px solid #fff;

  h1 {
        margin: 0;
        margin-left: 10px;
        padding: 0;
        letter-spacing: 4px;
        font-weight: 600;
        font-size: 1.5rem;
        text-transform: uppercase;
  }
`;

const HeadingWrapper = styled.div`
  width: 1200px;
`;

const Hero = ({title}) => (
  <HeroWrapper>
      <HeadingWrapper>
      <h1>{title}</h1>
      </HeadingWrapper>
  </HeroWrapper>
)

export default Hero
