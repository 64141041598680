const theme = {
    colors: {
        white: '#ffffff',
        black: '#000000',
        purple: '#6e06b6',
        darkGray: '#363636',
        lightGray: '#cccccc',
        header: '#f6f6f6',
        contact: '#f2f2f2'
    },
};

export default theme;