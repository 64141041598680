import PropTypes from "prop-types"
import React, { Component } from 'react';

import styled from 'styled-components'
import theme from "../styles/theme"

import LogoHeader from "../images/logo_header.png";

const StyledHeader = styled.header`
  background: ${theme.colors.header};
  color: ${theme.colors.black};
  marginBottom: 1.45rem;
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10px;
`;

const HorizontalList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: none;

  @media(min-width: 968px) {
    display: flex;
  }

  flex-direction: row;

  font-size: 11px;
  font-weight: normal;
  overflow: hidden;

  letter-spacing: 1.2px;

  text-transform: uppercase;

  li {
    display: block;
  } 
  
  @media(min-width: 968px)
  {
    &:li {
      display: inline-block;
    }
  }

  li a {
    display: block;
    height: auto;
    color: black;
    margin: 0;
    padding: 0;
    margin-right: 30px;
    text-align: center;
    text-decoration: none;
    padding: 5px 0px;
    border-bottom: 2px solid black;

    transition: color ease-in-out 0.2s;

    :hover {
      color: ${theme.colors.purple};
      border-bottom: 2px solid ${theme.colors.purple};
    }
  }
`;

const LastLink = styled.a`
  margin-right: 0px !important;
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    div {
      visibility: visible;
  opacity: 1;
    }
  }
`;

const DropdownContent = styled.div`
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s ease-in;

  position: fixed;
  color: black;
  text-decoration: none;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 10px 13px 10px 10px;

  text-align: left;

  a {
    color: black;
    text-align: left !important;
    padding: 12px 16px;
    letter-spacing: 1.2px;
    text-decoration: none;
    display: block;
    border-bottom: 0px !important;
    
    &:hover {
      color: ${theme.colors.purple};
    }
  }
`;

const MobileWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: white;

  background-color: ${props => props.visible ? 'rgba(0, 0, 0, 0.65)' : 'rgba(1,1,1,0)'};
  pointer-events: ${props => props.visible ? 'auto' : 'none'};
  @media(min-width: 968px) {
    display: none;
  }

  a {
    color: white;
  }

  z-index: 9998;
`;

const MenuButton = styled.button`
  position: fixed;
  width: 45px;
  height: 45px;
  top: 10px;
  right: 10px;
  z-index: 9999;
  padding: 0px;

  -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.15);
  box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.15);

  background-color: white;
  border: none;

  &:hover {
    cursor: pointer;
  }

  @media(min-width: 968px) {
    display: none;
  }

  div {
    width: 30px;
    height: 3px;
    background-color: black;
    margin: 6px 0;
    margin-left: 7.5px;
  }
`;

const MobileList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const LinkInfo = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
  width: 70px;
  height: auto;

  img {
    margin-bottom: 0px;
  }
  
  &:hover {
    color: ${theme.colors.purple};
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {showModal: false};
    this.handleClick = this.handleClick.bind(this);
  }

// function to handle the click
  handleClick() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }

  render() {
    return(
      <StyledHeader>
        <MenuButton onClick={this.handleClick}>
          <div/>
          <div/>
          <div/>
        </MenuButton>
        <MobileWrapper visible={this.state.showModal}>
          {this.state.showModal && <MobileList>
            <li><a href="/">STRONA GŁÓWNA</a></li>
            <li><a href="/o-nas">INFORMACJE O FIRMIE</a></li>
            <li><a href="/zakres-dzialalnosci">ZAKRES DZIAŁALNOŚCI</a></li>
            <li><a href="/oferta">OFERTA</a></li>
            <li><a href="/zapytanie-ofertowe">ZAPYTANIE OFERTOWE</a></li>
            <li><a href="/willach">WILLACH</a></li>
            <li><a href="/wdrozenia">WDROŻENIA</a></li>
            <li><LastLink href="/kontakt">KONTAKT</LastLink></li>
          </MobileList>}
        </MobileWrapper>
        <HeaderContainer>
          <LinkInfo href="/"><img src={LogoHeader}/></LinkInfo>
          <HorizontalList>
            <li><a href="/">STRONA GŁÓWNA</a></li>
            <li>
              <Dropdown>
                <a href="/o-nas">INFORMACJE O FIRMIE</a>
                <DropdownContent>
                  <a href="/zakres-dzialalnosci">ZAKRES DZIAŁALNOŚCI</a>
                </DropdownContent>
              </Dropdown> 
            </li>
            <li>
              <Dropdown>
                <a href="/oferta">OFERTA</a>
                <DropdownContent>
                  <a href="/system-hls">SYSTEM HLS</a>
                  <a href="/willach">WILLACH</a>
                  <a href="/wdrozenia  ">WDROŻENIA</a>
                  <a href="/zapytanie-ofertowe">ZAPYTANIE OFERTOWE</a>
                </DropdownContent>
              </Dropdown> 
            </li>
            <li><LastLink href="/kontakt">KONTAKT</LastLink></li>
          </HorizontalList>
        </HeaderContainer>
      </StyledHeader>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
