/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styled from 'styled-components';

import Header from "./Header"
import Footer from "./Footer"

const StyledWrapper = styled.div`
  width: auto;
  margin: 0 auto;
  padding 30px 0px;
  overflow-x: hidden;

  padding-left: 15px;
  padding-right: 15px;

  @media(min-width: 1200px) {
    max-width: 1200px;
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <StyledWrapper>
        <main>{children}</main>
      </StyledWrapper>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
